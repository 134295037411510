import React, {useEffect} from 'react';
import './styles/Header.css';
import {useState} from "react";
import Menu from "./Menu";
import MenuMoviles from "./MenuMoviles";
import {Outlet} from 'react-router-dom';
import SocialMediaMenu from "./redes_sociales/SocialMediaMenu";
import Footer from "../footer/Footer";


function Header() {

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);

    }, []);


    return (
        <>
            <header className={"header"}>
                <SocialMediaMenu/>
                {width >= breakpoint ?
                    <Menu/> : <MenuMoviles/>
                }
            </header>
            <Outlet/>
            <Footer/>

        </>
    )
}

export default Header;
