import React from 'react';
import {useParams} from 'react-router-dom'
import NoticiaDetalleForm from "../../../../componentes/noticias/admin/Forms";

export function NoticiaCrearPage() {
    return (
        <div className={"admin-pagina"}>
            <h1>Crear Noticia</h1>
            <NoticiaDetalleForm tipo={"crear"}/>
        </div>
    )
}

export function NoticiaEditarPage() {
    const idNoticia = useParams().id;

    return (
        <div className={"admin-pagina"}>
            <h1>Editar Noticia</h1>
            <NoticiaDetalleForm tipo={"editar"} id={idNoticia}/>
        </div>
    )
}
