import React from 'react';
import {LinkBox} from "../links/Links";
import {Card} from "react-bootstrap";
import './NoticiasCards.css';

function NoticiasCards(props) {
    const noticias = props.data;

    if (noticias) {
        return (
            <div className={"InformacionCards-card-container"}>
                {noticias.map((noticia, index) => {
                    return (
                        <Card key={index} className={'InformacionCards-card'}>
                            <LinkBox to={`/noticias/${noticia.id}`} reloadDocument>
                                <Card.Img className={'InformacionCards-card-img'} variant="top"
                                          src={noticia.imagen}/>
                            </LinkBox>
                            <Card.Body className={'InformacionCards-card-body'}>
                                <Card.Subtitle
                                    className={'InformacionCards-card-subtitle'}>{noticia.fecha}
                                </Card.Subtitle>
                                <LinkBox to={`/noticias/${noticia.id}`} reloadDocument>
                                    <Card.Title
                                        className={'InformacionCards-card-title'}>{noticia.titulo}
                                    </Card.Title>
                                </LinkBox>
                            </Card.Body>
                        </Card>
                    )
                })}
            </div>
        )
    }
}

export default NoticiasCards;
