import {Link, useLocation} from "react-router-dom";
import React, {useState} from 'react';
import './MenuSecundario.css'

function MenuSecundario(props) {
    const pathname = useLocation().pathname;
    const secciones = props.secciones

    function handleClick(e, seccion) {
        let element = document.getElementById(seccion.id)
        element.scrollIntoView({behavior: "smooth"})
    }

    return (
        <nav className={"menu-secundario-nav"} style={props.style}>
            <ul className={"menu-secundario-ul"}>
                {secciones.map((seccion, index) => {
                    return (
                        <li key={index} className="menu-secundario-li">
                            <Link className={`menu-secundario-navlink ${pathname === '/' ? 'active' : ''}`}
                                  onClick={(e) => {
                                      handleClick(e, seccion)
                                  }}
                            >
                                {seccion.titulo}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}

export default MenuSecundario;
