import React from 'react';
import malla_diplomado from "./../../paginas/FormacionContinua/imagenes/malla_diplomado.png";

export function Objetivos() {
    return (
        <>
            <p>
                El Programa de Diplomado en Educación Integral para un Aprendizaje Expansivo, tiene como objetivo
                aportar al
                mejoramiento de los desempeños de los docentes beneficiarios del proyecto en su Evaluación Docente,
                incrementando la cantidad calificado en calidad de experto.
            </p>
            <p>
                Al finalizar el programa de diplomado, sus egresados y egresadas tendrán las capacidades de:
            </p>
            <ul style={{margin: "2rem"}}>
                <li style={{listStyleType: "circle"}}><p>Diseñar experiencias de aprendizaje concordantes con los lineamientos del Marco para la Buena
                    Enseñanza y el Desarrollo Profesional Docente (Ley 20.903).</p>
                </li>
                <li style={{listStyleType: "circle"}}><p>Incorporar Metodologías Activas de la Enseñanza al diseño de sus experiencias de aprendizaje
                    profundo para el desarrollo de Habilidades para el Siglo XXI.</p>
                </li>
                <li style={{listStyleType: "circle"}}><p>Utilizar las tecnologías de la información y la comunicación (TICs) en el proceso de enseñanza
                    aprendizaje para el desarrollo de habilidades y competencias del Siglo XXI.</p>
                </li>
            </ul>
        </>
    )
}

export function MallaCurricular() {
    return <div style={{margin: "0 auto"}}>
        <div style={ {...{margin: "0 auto"}}}>
            <img style={{width: "100%"}} src={malla_diplomado} alt={"malla_curricular"}/>
        </div>
    </div>
}

export function InformacionGeneral() {
    return (
        <div className={"diplomado-informacion-general"}>
            <p><strong>Coordinador del Programa:</strong> DR© Alexander Pérez Mora.</p>
            <p><strong>Lugar Ejecución:</strong> Casa Central UNAP, Campus Playa Brava, Iquique.</p>
            <p><strong>Modalidad:</strong> Semipresencial (Presencial-sincrónica-asincrónica).</p>
            <p><strong>Jornada:</strong> Vespertina.</p>
            <p><strong>Horario de clases:</strong> Viernes de 18:00 a 21:00 hrs. (Sincrónica / 1 hora teórica + 2 horas
                de taller)
                Sábado de 09:00 a 13:00 hrs. (Presencial / 1 hora teórica + 3 horas de taller).
            </p>
            <p><strong>Duración:</strong> Total Horas Sincrónicas/Presenciales: 125 Horas
                Total Horas Asincrónicas/No Presenciales: 125 Horas
                Total Horas: 250 Horas
                Total Créditos: 9 SCT

            </p>
        </div>
    )
}

export function RequisitosIngreso() {
    return (
        <p>El Público Objetivo deL Programa de Diplomado en Educación Integral para un Aprendizaje Expansivo lo
            conforman profesores/as de segundo ciclo y Enseñanza Media.

            Requisitos de Postulación:

             Certificado de Título.
             Currículum Vitae
             Carta de Intensión.
        </p>
    )
}
