import './App.css';
import {Routes, Route, Outlet} from 'react-router-dom';
import InicioPage from "./paginas/Inicio/InicioPage";
import NoticiasInicioPage from "./paginas/Noticias/NoticiasInicioPage";
import NoticiasDetallePage from "./paginas/Noticias/NoticiasDetallePage";
import FormacionContinuaPage from "./paginas/FormacionContinua/FormacionContinuaPage";
import EventosInicioPage from "./paginas/Eventos/EventosInicioPage";
import EventosDetallePage from "./paginas/Eventos/EventosDetallePage";
import CasosExitososInicioPage from "./paginas/CasosExitosos/CasosExitososInicioPage";
import CasosExitososDetallePage from "./paginas/CasosExitosos/CasosExitososDetallePage";
import RecursosPedagogicosInicioPage from "./paginas/RecursosPedagogicos/RecursosPedagogicosInicioPage";
import RecursosPedagogicosDetallePage from "./paginas/RecursosPedagogicos/RecursosPedagogicosDetallePage";

import './Styles/fonts.css';
import "@fontsource/titillium-web/700.css";
import "@fontsource/titillium-web/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sauce-sans/700.css";

import Header from "./componentes/menus/Header";
import Admin from "./admin/Admin";
import {NoticiaCrearPage, NoticiaEditarPage} from "./admin/paginas/Noticias/crear/NoticiaFormPage";
import Login from "./admin/paginas/Login/Login";
import NoticiasAdminPage from "./admin/paginas/Noticias/landing/NoticiasAdminPage";
import FormacionContinuaPostulacionPage from "./paginas/FormacionContinua/FormacionContinuaPostulacionPage";


function App() {
    return (
        <>
            <Routes>
                <Route path="" element={<Header/>}> {/* TODO organizar mejor header */}
                    <Route path="" element={<InicioPage/>}/>
                    <Route path="noticias" element={<NoticiasInicioPage/>}/>
                    <Route path="noticias/:id" element={<NoticiasDetallePage/>}/>
                    <Route path="formacion_continua" element={<FormacionContinuaPage/>}/>
                    <Route path="formacion_continua/postular" element={<FormacionContinuaPostulacionPage/>}/>
                    <Route path="eventos" element={<EventosInicioPage/>}/>
                    <Route path="eventos/:id" element={<EventosDetallePage/>}/>
                    <Route path="casos_exitosos" element={<CasosExitososInicioPage/>}/>
                    <Route path="casos_exitosos/:id" element={<CasosExitososDetallePage/>}/>
                    <Route path="recursos_pedagogicos" element={<RecursosPedagogicosInicioPage/>}/>
                    <Route path="recursos_pedagogicos/:id" element={<RecursosPedagogicosDetallePage/>}/>
                </Route>
                <Route path="ingresar" element={<Login/>}/>
                <Route path="admin" element={<Admin/>}>
                    <Route path="noticias" element={<Outlet/>}>
                        <Route path="" element={<NoticiasAdminPage/>}/>
                        <Route path="crear" element={<NoticiaCrearPage tipo={"crear"}/>}/>
                        <Route path="editar/:id" element={<NoticiaEditarPage/>} tipo={"editar"}/>
                    </Route>
                </Route>

                {/*<Route path="admin" element={<admin/>}>
                    <Route path="noticias" element={<NoticiasAdminPage/>}/>
                    <Route path="noticias/crear" element={<NoticiasCrearPage/>}/>
                </Route>*/}
            </Routes>
        </>
    );
}

export default App;
