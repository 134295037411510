import React from "react";
import './styles/Menu.css';
import {LinkLogoRedAulaFutura} from "./Common";
import {MenuNav} from "./Common";

function Menu() {
    const secciones = [
        {
            titulo: "Inicio",
            to: ""
        },
        {
            titulo: "Noticias",
            to: "noticias"
        },
        {
            titulo: "Formación Continua",
            to: "formacion_continua"
        }
    ]

    return (
        <div className={"menu Menu-contenido"}>
            <LinkLogoRedAulaFutura className={"Menu-logo"}/>
            <MenuNav secciones={secciones}/>
        </div>
    );
}

export default Menu;
