// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
}

.header-container {
}

.menu{
    max-width: min(90vw, 100rem);
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/menus/styles/Header.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;;AAEA;IACI,4BAA4B;IAC5B,cAAc;AAClB","sourcesContent":[".Header {\n}\n\n.header-container {\n}\n\n.menu{\n    max-width: min(90vw, 100rem);\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
