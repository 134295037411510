// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-logo{
    width: 200px;
    height: auto;
}

.login-page-wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-page-content{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid var(--gris_red_aula_futura);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page-content h1{
    font-size: 2rem;
}

`, "",{"version":3,"sources":["webpack://./src/admin/paginas/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,6CAA6C;IAC7C,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".login-logo{\n    width: 200px;\n    height: auto;\n}\n\n.login-page-wrapper{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.login-page-content{\n    padding: 30px;\n    border-radius: 6px;\n    border: 1px solid var(--gris_red_aula_futura);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.login-page-content h1{\n    font-size: 2rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
