// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formulario-postulacion{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 50%;
    color: var(--azul_unap_oscuro)
}

.formulario-postulacion-seccion{
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}

.formulario-postulacion-campo{
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}

.formulario-postulacion-enviar{
    background-color: var(--azul_unap_claro);
    color: white;
    margin: 15px auto;
    border-radius: 15px;
    width: 200px;
    height: 50px;
    border: none;
    font-weight: bold;
    font-size: 1.2rem;
}

.formulario-postulacion-label{
    font-weight: bold;
}

.formulario-postulacion-requerido{
    color: red;
}

.formulario-postulacion-error{
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/paginas/FormacionContinua/formacion-continua-postulacion-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,UAAU;IACV;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,wCAAwC;IACxC,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".formulario-postulacion{\n    display: flex;\n    flex-direction: column;\n    margin: 0 auto;\n    width: 50%;\n    color: var(--azul_unap_oscuro)\n}\n\n.formulario-postulacion-seccion{\n    display: flex;\n    flex-direction: column;\n    margin: 5px 0;\n}\n\n.formulario-postulacion-campo{\n    display: flex;\n    flex-direction: column;\n    margin: 5px 0;\n}\n\n.formulario-postulacion-enviar{\n    background-color: var(--azul_unap_claro);\n    color: white;\n    margin: 15px auto;\n    border-radius: 15px;\n    width: 200px;\n    height: 50px;\n    border: none;\n    font-weight: bold;\n    font-size: 1.2rem;\n}\n\n.formulario-postulacion-label{\n    font-weight: bold;\n}\n\n.formulario-postulacion-requerido{\n    color: red;\n}\n\n.formulario-postulacion-error{\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
