// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabla {
    width: 100%;
}

.table-wrapper{
    width: 900px;
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/tablas/Tabla.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".tabla {\n    width: 100%;\n}\n\n.table-wrapper{\n    width: 900px;\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
