import {Link} from "react-router-dom";
import React from "react";

export const LinkBox = (props) => {
    return (
        <Link style={{textDecoration: 'inherit', color: 'inherit'}} reloadDocument={props.reloadDocument} to={props.to}>
            {props.children}
        </Link>
    );
}


export const Redirect = ({to}) => {
    window.location.replace(to);
    return null;
}
