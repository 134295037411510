import {Outlet} from "react-router-dom";
import LandingPage from "./paginas/LandingPage";
import React from "react";
import './styles/styles.css'

function Admin() {
    return (
        <div style={{display: "flex"}}>
            <LandingPage/>
            <Outlet/>
        </div>

    );
}


export default Admin;
