import React, {useState} from 'react';
import './formacion-continua-postulacion-page.css'
import {postRequest} from "../../requests/requests";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {formatearRut} from "../../componentes/formularios/formateadores";
import {validarEmail, validarExistencia, validarRut, validarTelefono} from "../../componentes/formularios/validadores";

export default function FormacionContinuaPostulacionPage() {
    const navigate = useNavigate();

    const [postulacion, setPostulacion] = useState({
        nombre_completo: '',
        rut: '',
        correo_electronico: '',
        telefono: '+56 ',
        institucion: '',
        area_disciplinar: '',
        curriculum_vitae: '',
        certificado_titulo: ''
    })

    const [errors, setErrors] = useState({
        nombre_completo: '',
        rut: '',
        correo_electronico: '',
        telefono: '',
        institucion: '',
        area_disciplinar: '',
        curriculum_vitae: '',
        certificado_titulo: ''
    });

    function handleChange(event) {
        let {name, value} = event.target;
        if (name === 'rut') {
            value = formatearRut(value)
        }

        if (name === 'telefono'){
            value = `+56 ${value.substring(4).replace(/\D/g, '').substring(0, 9)}`
        }

        setPostulacion((postulacion) => ({...postulacion, [name]: value}))
    }

    function handleFileChange(event) {
        setPostulacion((postulacion) => ({...postulacion, [event.target.name]: event.target.files[0]}))
    }

    async function handleSubmit(event) {
        event.preventDefault();
        let errors = {
            nombre_completo: '',
            rut: '',
            correo_electronico: '',
            telefono: '',
            institucion: '',
            area_disciplinar: '',
            curriculum_vitae: '',
            certificado_titulo: ''
        }
        let isValid = true

        const validacionExistenciaNombreCompleto = validarExistencia(postulacion.nombre_completo)
        if (!validacionExistenciaNombreCompleto.valido) {
            errors.nombre_completo = validacionExistenciaNombreCompleto.mensaje
            isValid = false
        }

        const validacionRut = validarRut(postulacion.rut)
        if (!validacionRut.valido) {
            errors.rut = validacionRut.mensaje
            isValid = false
        }

        const validacionCorreoElectonico = validarEmail(postulacion.correo_electronico)
        if (!validacionRut.valido) {
            errors.correo_electronico = validacionCorreoElectonico.mensaje
            isValid = false
        }

        const validacionTelefono = validarTelefono(postulacion.telefono)
        if (!validacionTelefono.valido) {
            errors.telefono = validacionTelefono.mensaje
            isValid = false
        }

        const validacionInstitucion = validarExistencia(postulacion.institucion)
        if (!validacionInstitucion.valido) {
            errors.institucion = validacionInstitucion.mensaje
            isValid = false
        }

        const validacionAreaDisciplinar = validarExistencia(postulacion.area_disciplinar)
        if (!validacionAreaDisciplinar.valido) {
            errors.area_disciplinar = validacionAreaDisciplinar.mensaje
            isValid = false
        }


        if (postulacion.curriculum_vitae === '') {
            errors.curriculum_vitae = "Este campo es requerido"
            isValid = false
        }

        if (postulacion.certificado_titulo === '') {
            errors.certificado_titulo = "Este campo es requerido"
            isValid = false
        }


        setErrors(errors)


        if (isValid) {
            let data = new FormData();
            data.append('nombre_completo', postulacion.nombre_completo);
            data.append('rut', postulacion.rut);
            data.append('correo_electronico', postulacion.correo_electronico);
            data.append('telefono', postulacion.telefono);
            data.append('institucion', postulacion.institucion);
            data.append('area_disciplinar', postulacion.area_disciplinar);
            data.append('curriculum_vitae', postulacion.curriculum_vitae);
            data.append('certificado_titulo', postulacion.certificado_titulo);


            await postRequest('formacion_continua/postular/', data).then((response) => {
                    if (response.status === 201) {
                        Swal.fire({
                            title: "Su postulación ha sido enviada exitosamente",
                            text: "Hemos recibido su postulación",
                            icon: "success",
                        }).then((result) => {
                            if (result.isConfirmed || result.isDismissed) {
                                navigate('/formacion_continua')
                            }
                        });
                    }
                }
            ).catch(error => console.log(error));
        }


    }

    return (
        <div className={"pagina-container"}>
            <form className={"formulario-postulacion"} onSubmit={handleSubmit}>
                <h3>Postulación al Programa "Diplomado en Educación Integral para un Aprendizaje Expansivo"</h3>
                <div className={"formulario-postulacion-seccion"}>
                    <h4>1. Identificación del Postulante</h4>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Nombre completo <span
                            className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.nombre_completo ?
                            <span className={"formulario-postulacion-error"}>{errors.nombre_completo}</span> : null}
                        <input type="text" id="nombre_completo" name="nombre_completo"
                               value={postulacion.nombre_completo}
                               onChange={handleChange}/>
                    </div>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Rut <span
                            className={"formulario-postulacion-requerido"}>*</span></label>
                        {errors.rut ?
                            <span className={"formulario-postulacion-error"}>{errors.rut}</span> : null}
                        <input type="text" id="rut" name="rut" value={postulacion.rut}
                               onChange={handleChange}/>
                    </div>
                </div>
                <div className={"formulario-postulacion-seccion"}>
                    <h4>2. Datos de Contacto</h4>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Correo electrónico <span
                            className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.correo_electronico ?
                            <span className={"formulario-postulacion-error"}>{errors.correo_electronico}</span> : null}
                        <input type="text" id="correo_electronico" name="correo_electronico"
                               value={postulacion.correo_electronico} onChange={handleChange}/>
                    </div>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Número telefónico <span
                            className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.telefono ?
                            <span className={"formulario-postulacion-error"}>{errors.telefono}</span> : null}
                        <input type="text" id="telefono" name="telefono" value={postulacion.telefono}
                               onChange={handleChange}/>
                    </div>
                </div>
                <div className={"formulario-postulacion-seccion"}>
                    <h4>3. Información profesional</h4>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Nombre de la institución donde
                            imparte la docencia <span className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.institucion ?
                            <span className={"formulario-postulacion-error"}>{errors.institucion}</span> : null}
                        <input type="text" id="institucion" name="institucion" value={postulacion.institucion}
                               onChange={handleChange}/>
                    </div>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor="fname">Área disciplinar <span
                            className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.area_disciplinar ?
                            <span className={"formulario-postulacion-error"}>{errors.area_disciplinar}</span> : null}
                        <input type="text" id="area_disciplinar" name="area_disciplinar"
                               value={postulacion.area_disciplinar}
                               onChange={handleChange}/>
                    </div>
                </div>
                <div className={"formulario-postulacion-seccion"}>
                    <h4>4. Documentos de Postulación</h4>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor={"fname"}>Currículum vitae <span
                            className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.curriculum_vitae ?
                            <span className={"formulario-postulacion-error"}>{errors.curriculum_vitae}</span> : null}
                        <input
                            type="file"
                            id="curriculum_vitae"
                            name="curriculum_vitae"
                            accept={".pdf"}
                            value={undefined}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className={"formulario-postulacion-campo"}>
                        <label className={"formulario-postulacion-label"} htmlFor={"fname"}>Certificado de
                            título <span className={"formulario-postulacion-requerido"}>*</span> </label>
                        {errors.certificado_titulo ?
                            <span className={"formulario-postulacion-error"}>{errors.certificado_titulo}</span> : null}
                        <input
                            type="file"
                            id="certificado_titulo"
                            name="certificado_titulo"
                            accept={".pdf, .jpg, .png, .jpeg, .HEIC"}
                            value={undefined}
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
                <input className={"formulario-postulacion-enviar"} type="submit" value="Enviar Postulación"/>
            </form>
        </div>
    )
}
