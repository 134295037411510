import Carousel from 'react-bootstrap/Carousel';
import './CarruselFormacionContinua.css'
import {useState, useEffect} from "react";
import axios from "axios";
import {LinkBox} from "../../links/Links";


function ImagenBanner(props) {

    const [imagenesBanner] = useState(props.imagenes);

    /*
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/inicio/imagenes_banner`).then((response) => {
            setImagenesBanner(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, [])
    */
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        imagenesBanner.length ?
            <div className={"carrusel-container"}>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    {imagenesBanner.map((bannerElement, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <img
                                    className="carrusel-imagen"
                                    src={bannerElement.imagen}
                                    alt={bannerElement.titulo}
                                />
                            </Carousel.Item>);
                    })}
                </Carousel>
                <div className="carrusel-contenido-container">
                    <div className="carrusel-contenido">
                        <span>{imagenesBanner[index].titulo}</span>
                        <LinkBox to={"postular"}>
                            <button>POSTULAR</button>
                        </LinkBox>
                    </div>
                </div>
            </div>
            :
            null
    );
}

export default ImagenBanner;

