export const validarExistencia = (input) => {
    let validacion = {valido: true, mensaje: ''};
    if (!input.trim()) {
        validacion = {valido: false, mensaje: "Este campo es requerido"}
    }
    return validacion
}

const calcularDigitoVerificador = (rut) => {
    /**
     * Calcula el dígito verificador de un número
     * Ej. 11.111.111-1 -> 1
     *     19.426.870-K -> k
     * */
    let digitos = rut.replace(/[^0-9Kk]/g, '')
    let cuerpo = digitos.substring(0, digitos.length - 1)

    let cuerpo_reverso = cuerpo.split("").map((digito) => {
        return parseInt(digito, 10)
    }).reverse()
    let serie = [2, 3, 4, 5, 6, 7]

    let suma = 0
    for (let i = 0; i < cuerpo_reverso.length; i++) {
        suma += cuerpo_reverso[i] * serie[i % serie.length]
    }

    let digito = (11 - (suma % 11)).toString()

    if (digito === "10")
        digito = "k"
    else if (digito === "11")
        digito = "0"

    return digito
}

export const validarRut = (rut) => {
    let validacion = {valido: true, mensaje: ''};

    rut = rut.toLowerCase()

    let validacionExistencia = validarExistencia(rut)
    if (!validacionExistencia.valido) {
        validacion = validacionExistencia
        return validacion
    }

    const regexpRut = /^\d{1,2}[.]?\d{3}[.]?\d{3}[-]?[\dkK]$/
    let validacionFormato = regexpRut.test(rut)
    if (!validacionFormato) {
        validacion = {valido: false, mensaje: "El rut ingresado no es válido"}
        return validacion
    }

    let digitoVerificador = calcularDigitoVerificador(rut)
    let ultimoDigitoRut = rut.charAt(rut.length - 1)
    if (digitoVerificador !== ultimoDigitoRut) {
        validacion = {valido: false, mensaje: "El rut ingresado no es válido"}
        return validacion
    }
    return validacion;
}

export const validarEmail = (email) => {
    let validacion = {valido: true, mensaje: ''};

    let validacionExistencia = validarExistencia(email)
    if (!validacionExistencia.valido) {
        validacion = validacionExistencia
        return validacion
    }

    const regexpEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let validacionFormato = regexpEmail.test(email)
    if (!validacionFormato) {
        validacion = {valido: false, mensaje: "El correo electrónico ingresado no es válido"}
        return validacion
    }

    return validacion
}

export const validarTelefono = (telefono) => {
    /**
     * Telefono existe si es distinto a '' y distinto a "+56 ".
     * Teléfono cumple el formato +56 XXXXXXXXX
     * @type {{valido: boolean, mensaje: string}}
     */
    let validacion = {valido: true, mensaje: ''};

    let validacionExistencia = !((telefono === '') || (telefono === '+56 '))
    if (!validacionExistencia) {
        validacion = {valido: false, mensaje: "Este campo es requerido"}
        return validacion
    }

    const regexpTelefono = /^\+56 \d{9}$/i;
    let validacionFormato = regexpTelefono.test(telefono)
    if (!validacionFormato) {
        validacion = {valido: false, mensaje: "El númere ingresado no es válido"}
        return validacion
    }

    return validacion
}


