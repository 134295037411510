// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.login-form input{
    width: 100%;
    margin: 5px auto;
    height: 40px;
    border-radius: 6px;
    border: 2px var(--naranjo_red_aula_futura) solid;
    padding: 20px;
}

.login-form label{
    width: 100%;
    margin: 10px auto;
    font-size: 1.1rem;
}

.login-form button{
    background-color: var(--naranjo_red_aula_futura);
    border: none;
    border-radius: 6px;
    margin: 20px auto;
    height: 40px;
    width: 100px;
    color: white;
    font-weight: 700;

}
`, "",{"version":3,"sources":["webpack://./src/admin/paginas/Login/Forms/LoginForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,gDAAgD;IAChD,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,gDAAgD;IAChD,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,gBAAgB;;AAEpB","sourcesContent":[".login-form{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: 20px auto;\n}\n\n.login-form input{\n    width: 100%;\n    margin: 5px auto;\n    height: 40px;\n    border-radius: 6px;\n    border: 2px var(--naranjo_red_aula_futura) solid;\n    padding: 20px;\n}\n\n.login-form label{\n    width: 100%;\n    margin: 10px auto;\n    font-size: 1.1rem;\n}\n\n.login-form button{\n    background-color: var(--naranjo_red_aula_futura);\n    border: none;\n    border-radius: 6px;\n    margin: 20px auto;\n    height: 40px;\n    width: 100px;\n    color: white;\n    font-weight: 700;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
