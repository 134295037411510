import React, {useState} from 'react';
import './LoginForm.css';
import {useSearchParams} from "react-router-dom";
import {loginRequest} from "../../../../requests/requests";

export default function LoginForm() {

    const [searchParams] = useSearchParams()
    const [usuario, setUsuario] = useState({rut: '', password: ''})
    const [errors, setErrors] = useState({});

    const next = searchParams.get('next')
    const next_location = next ? next : '/admin'

    const handleSubmit = event => {
        event.preventDefault();
        if (validateForm()) {
            // TODO errors en login
            let errors = loginRequest(`${process.env.REACT_APP_API_URL}/auth/login/`, usuario, next_location)
        }
    }

    const handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        setUsuario((values => ({...values, [name]: value})))
    }

    const validateForm = () => {
        let esValido = true;
        const formularioErrores = {};

        if (!usuario.rut) {
            formularioErrores.rut = "Debe ingresar su Rut";
            esValido = false;
        }

        if (!usuario.password) {
            formularioErrores.password = "Debe ingresar una contraseña";
            esValido = false;
        }

        setErrors(formularioErrores);
        return esValido;
    };

    return (
        <div className={"login-form-wrapper"}>
            <form className={"login-form"} onSubmit={handleSubmit}>
                <label>
                    <strong>Rut</strong>
                    <input type="text" name="rut" value={usuario.rut} onChange={handleChange}/>
                    {errors.rut && <div className="error">{errors.rut}</div>}
                </label>
                <label>
                    <strong>Contraseña</strong>
                    <input type="password" name="password" value={usuario.password} onChange={handleChange}/>
                    {errors.password && <div className="error">{errors.password}</div>}
                </label>
                {errors.formulario && <div className="error">{errors.formulario}</div>}
                <button type="submit">Ingresar</button>
            </form>
        </div>
    )
}
