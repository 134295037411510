import React from 'react';
import './styles/Menu.css'
import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {LinkLogoRedAulaFutura} from "./Common";
import {MenuNav} from "./Common";


function MenuMoviles() {
    const secciones = [
        {
            titulo: "Inicio",
            to: ""
        },
        {
            titulo: "Noticias",
            to: "noticias"
        },
        {
            titulo: "Formación Continua",
            to: "formacion_continua"
        }
    ]

    const [isOpen, setIsOpen] = useState(false)

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={"Menu-container"}>
            <div className={"Menu-contenido"}>
                <div className={"Menu-contenido-closed"}>
                    <LinkLogoRedAulaFutura className={"Menu-logo"} classNamePosition={"Menu-logo-horizontal-center"}
                                           reloadDocument={true}/>
                    <div className={"Menu-icon-container"} onClick={handleToggle}>
                        <FontAwesomeIcon className={"Menu-icon-bars"} icon={faBars}/>
                        <div className={"Menu-icon-text"}>Menú</div>
                    </div>
                </div>
                <div className="Menu-contenido-open" aria-expanded={!isOpen}>
                    <MenuNav secciones={secciones} reloadDocument={true}/>
                </div>
            </div>
        </div>
    );
}

export default MenuMoviles;
