import {useEffect, useState} from "react";
import './NoticiasForm.css'
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {es} from "date-fns/locale";
import {getRequest, POSTAuthorizationRequest} from "../../../requests/requests";
import {useNavigate} from "react-router-dom";

registerLocale("es", es);

export default function NoticiaDetalleForm(props) {
    const navigate = useNavigate();
    const [noticia, setNoticia] = useState({
        fecha: '',
        titulo: '',
        bajada: '',
        cuerpo: '',
        imagen: '',
        url_uriginal: '',
    })

    const tipo = props.tipo;
    useEffect(() => {
        if (tipo === "editar") {
            const idNoticia = props.id;
            void getRequest(`noticias/admin/noticias/detalle/${idNoticia}`, setNoticia)
        }
    }, [tipo, props.id])


    const changeNoticia = (name, value) => {
        setNoticia((prevFormData) => ({...prevFormData, [name]: value}));
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        changeNoticia(name, value);
    };

    const handleDateChange = (date) => {
        changeNoticia('fecha', date);
    }

    const handleFileChange = (event) => {
        changeNoticia([event.target.name], event.target.files[0])
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let data = new FormData();

        data.append('fecha', noticia.fecha.toISOString().split('T')[0]);
        data.append('titulo', noticia.titulo);
        data.append('bajada', noticia.bajada);
        data.append('cuerpo', noticia.cuerpo);
        data.append('imagen', noticia.imagen);
        data.append('url_original', noticia.url_uriginal);

        await POSTAuthorizationRequest('noticias/admin/noticias/crear/', data).then((response) => {
                if (response.status === 201) {
                    navigate('/admin/noticias')
                }
            }
        ).catch(error => console.log(error));
    }

    return (
        <form className={"form"} onSubmit={handleSubmit}>
            <label style={{width: "100%"}} htmlFor="fname">Fecha:</label>
            <DatePicker name="fecha" selected={noticia.fecha}
                        locale="es"
                        dateFormat="EEEE d 'de' MMMM 'de' YYYY"
                        showIcon
                        autoComplete="off"
                        onChange={handleDateChange}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
            />
            <label htmlFor="fname">Título:</label>
            <input type="text" id="titulo" name="titulo" value={noticia.titulo} onChange={handleChange}/>
            <label htmlFor="fname">Bajada:</label>
            <textarea name="bajada" value={noticia.bajada} style={{resize: "none"}} onChange={handleChange}/>
            <label htmlFor="fname">Cuerpo:</label>
            <textarea name="cuerpo" value={noticia.cuerpo} style={{resize: "none"}} onChange={handleChange}/>
            <label htmlFor={"fname"}>Imagen:</label>
            <input
                name="imagen"
                type="file"
                accept={".jpg, .png, .jpeg, .HEIC"}
                value={undefined}
                onChange={handleFileChange}
            />
            <label htmlFor={"fname"}>Link original:</label>
            <input
                type="url"
                value={noticia.url_original}
                onChange={handleChange}>
            </input>
            <input type="submit" value="Enviar"/>
            {/*<ReactQuill theme="snow" value={cuerpo} name="cuerpo" onChange={setCuerpo} />*/}
        </form>
    )

}
