import React, {useEffect, useState} from 'react';
import axios from "axios";
import '../../Styles/pagina.css'
import {useParams} from "react-router-dom";
import SeccionContainer from "../../componentes/formacion_continua/SeccionContainer";
import Tab from "../../componentes/formacion_continua/Tab";
import img1 from "./imagenes/img1.jpeg";
import img2 from "./imagenes/img2.jpeg";
import CarruselFormacionContinua from "../../componentes/carruseles/FormacionContinua/CarruselFormacionContinua";
import MenuSecundario from "../../componentes/menus/MenuSecundario";
import logo_unap from "../../componentes/footer/logo_unap2.png"
import logo_cepip from "./imagenes/logo_cpeip.jpeg"
import './FormacionContinua.css'
import Card from "../../componentes/formacion_continua/Card/Card";
import UltimasNoticias from "../Inicio/UltimasNoticias";

function FormacionContinuaPage() {

    const pagina = Number(useParams().page)
    const [noticias, setNoticias] = useState([])
    const [numeroDePaginas, setNumeroDePaginas] = useState(0)

    const img = [
        {
            imagen: img1,
            titulo: "Diplomado en Educación Integral para un Aprendizaje Expansivo"
        },
        {
            imagen: img2,
            titulo: "Diplomado en Educación Integral para un Aprendizaje Expansivo"
        }
    ]

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/noticias/${pagina ? `?pagina=${pagina}` : ''}`).then((response) => {
            setNoticias(response.data);
            setNumeroDePaginas(response.data.numero_de_paginas)
        }).catch(error => {
            console.log(error);
        });
    }, [pagina])

    const secciones = [
        {
            titulo: "Acerca del programa",
            id: "acerca_del_programa",
            to: "#acerca_del_programa"
        },
        {
            titulo: "Información curricular",
            id: "informacion_curricular",
            to: "#informacion_curricular"
        },
        {
            titulo: "¿Qué nos distingue?",
            id: "que_nos_distingue",
            to: "#que_nos_distingue"
        },
        /**
        {
            titulo: "Nuestros/as estudiantes",
            id: "nuestros_estudiantes",
            to: "#nuestros_estudiantes"
        },
        {
            titulo: "Nuestras actividades",
            id: "conoce_nuestras_actividades",
            to: "#conoce_nuestras_actividades"
        },
        {
            titulo: "Próximos Eventos y Actividades",
            id: "proximos_eventos_y_actividades",
            to: "#proximos_eventos_y_actividades"
        },
            */
    ]

    return (
        <>
            <MenuSecundario style={{color: "white", backgroundColor: "var(--naranjo_red_aula_futura)"}}
                            secciones={secciones}/>
            <CarruselFormacionContinua imagenes={img}/>
            <div className={'pagina-container'} style={{color: "var(--azul_unap_oscuro)"}}>
                <SeccionContainer id={secciones[0].id} titulo={secciones[0].titulo}>
                    <p>El <strong><em>Diplomado en Educación Integral para un Aprendizaje Expansivo</em></strong>, que
                        es parte del "Proyecto Red Aula Futura", financiado por nuestro <em>Gobierno Regional</em> y
                        ejecutado por la <em>Facultad de Ciencias Humanas de la Universidad Arturo Prat;</em> tiene por
                        objetivo <em>"la mejora de los procesos de enseñanza y aprendizaje al interior de las aulas
                            tarapaqueñas, por medio del perfeccionamiento de los profesores y profesoras de 15
                            establecimientos educacionales beneficiarios del proyecto,
                            en el diseño de experiencias de aprendizaje que faciliten el desarrollo de las habilidades,
                            contenidos y actitudes propuestas en las Bases Curriculares y Programas de Estudio,
                            respetando los 4 Dominios del Marco de la Buena Enseñanza y en concordancia con los
                            lineamientos referidos a planificación, evaluación y reflexión de la Ley N°20.903 de
                            Desarrollo Profesional Docente, según los requerimientos del Centro de Perfeccionamiento,
                            Experimentación e Investigaciones Pedagógicas (CPEIP)"</em>.
                    </p>
                    <p>
                        Así, se espera <em>potenciar la innovación didáctica y metodológica,</em> orientando a los
                        docentes en la construcción de <em>Insumos Pedagógicos Digitales</em> y la incorporación de <em>Metodologías
                        Activas de la Enseñanza</em> en sus prácticas de aula, de acuerdo a la teoría de la <em>5ta
                        dimensión</em>, la cual pone en su centro el proceso reflexivo acerca de las propias prácticas
                        con el fin de procurar un <em>Aprendizaje Expansivo</em>. Además, se les apoya en el proceso de
                        planificación,
                        construcción e implementación de insumos digitales, con la finalidad de impactar en la
                        motivación de los y las
                        estudiantes por aprender y potenciar el desarrollo de las <em>competencias del Siglo XXI</em>.
                    </p>
                    <p>Así, el <strong><em>Diplomado en Educación Integral para un Aprendizaje
                        Expansivo</em></strong> permitirá incrementar la
                        cantidad de docentes distribuidos en calidad de experto en la Evaluación de Desempeño
                        Docente.
                    </p>

                </SeccionContainer>
                <SeccionContainer id={secciones[1].id} titulo={secciones[1].titulo}>
                    <Tab/>
                </SeccionContainer>
                <SeccionContainer id={secciones[2].id} titulo={secciones[2].titulo}>
                    <h3 style={{textDecoration: "underline", margin: "1.5rem auto"}}>Elementos innovadores
                    </h3>
                    <ul style={{margin: "2rem"}}>
                        <li style={{listStyleType: "circle"}}><p>Es el primer diplomado en la UNAP que virtualiza sus
                            horas asincrónicas.
                        </p></li>
                        <li style={{listStyleType: "circle"}}><p>Es el único en la macrozona norte destinado a que
                            los/as docentes puedan preparar su portafolio docente.
                        </p></li>
                        <li style={{listStyleType: "circle"}}><p>Es el único Programa de Formación continua del país que
                            aborda la Teoría del Aprendizaje Expansivo.
                        </p></li>
                        <li style={{listStyleType: "circle"}}><p>Aborda temáticas actuales como Competencias del siglo
                            XXI, enseñanza de la STEM e Inteligencia Artificial.
                        </p></li>

                    </ul>
                    {/**
                    <h3 style={{textDecoration: "underline", margin: "1.5rem auto"}}>Certificación CPEIP</h3>
                    <div style={{display: "flex", columnGap: "1.2rem", alignItems: "center", justifyContent: "center"}}>
                        <img src={logo_cepip} alt={"logo_unap"} style={{height: "150px"}}/>
                        <p>El diplomado cuenta con certificación CPEIP</p>
                    </div>
                    */}
                    <h3 style={{textDecoration: "underline", margin: "1.5rem auto"}}>Vínculos internacionales</h3>
                    <p>El diplomado cuenta con la relatoría de académicos/as de clase mundial de Universidades de
                        Finlandia y Suecia (líderes en educación mundial).
                    </p>
                    <Card/>
                </SeccionContainer>
                {/**}
                <SeccionContainer id={secciones[4].id} titulo={secciones[4].titulo}>
                    <UltimasNoticias></UltimasNoticias>
                </SeccionContainer>
                <SeccionContainer id={secciones[5].id} titulo={secciones[5].titulo}>
                    <div style={{display: "table", width: "100%", margin: "0 auto"}}>
                        <div style={{}}>
                            <div style={{display: "flex", width: "49%", float: "left", margin: "1% 1% 1% 0"}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "var(--azul_unap_oscuro)",
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "100%",
                                    fontSize: "2.5rem",
                                    lineHeight: "1"
                                }}>
                                    <div>3</div>
                                    <div style={{fontSize: "1.8rem"}}>MAY</div>
                                </div>
                                <p style={{alignSelf: "center"}}>Ceremonia de inauguración del diplomado</p>
                            </div>
                            <div style={{display: "flex", width: "49%", float: "left", margin: "1% 0 1% 1%"}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "var(--azul_unap_oscuro)",
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "100%",
                                    fontSize: "2.5rem",
                                    lineHeight: "1"
                                }}>
                                    <div>9</div>
                                    <div style={{fontSize: "1.8rem"}}>MAY</div>
                                </div>
                                <p style={{alignSelf: "center"}}>Ceremonia de inauguración del diplomado</p>
                            </div>
                            <div style={{display: "flex", width: "49%", float: "left", margin: "1% 1% 1% 0"}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "var(--azul_unap_oscuro)",
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "100%",
                                    fontSize: "2.5rem",
                                    lineHeight: "1"
                                }}>
                                    <div>12</div>
                                    <div style={{fontSize: "1.8rem"}}>MAY</div>
                                </div>
                                <p style={{alignSelf: "center"}}>Ceremonia de inauguración del diplomado</p>
                            </div>
                            <div style={{display: "flex", width: "49%", float: "left", margin: "1% 0 1% 1%"}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "var(--azul_unap_oscuro)",
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "100%",
                                    fontSize: "2.5rem",
                                    lineHeight: "1"
                                }}>
                                    <div>25</div>
                                    <div style={{fontSize: "1.8rem"}}>MAY</div>
                                </div>
                                <p style={{alignSelf: "center"}}>Ceremonia de inauguración del diplomado</p>
                            </div>
                        </div>
                    </div>
                </SeccionContainer>
    */}

            </div>
        </>
    )
}

export default FormacionContinuaPage


