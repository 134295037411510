import MenuLateral from "../../componentes/menus/admin/menu_lateral/MenuLateral";
import {secciones} from "./Secciones"

function LandingPage() {
    return (
        <div>
            <MenuLateral secciones={secciones}/>
        </div>
    )
}

export default LandingPage
