import React, {useEffect} from 'react';
import './UltimasNoticias.css';
import {useState} from "react";
import NoticiasCards from '../../componentes/noticias/NoticiasCards'
import {LinkBox} from "../../componentes/links/Links";
import {getRequest} from "../../requests/requests";

function UltimasNoticias() {
    const [noticias, setNoticias] = useState([])
    const titulo_seccion = 'Últimas noticias'

    useEffect(() => {
        getRequest('noticias/ultimas/', setNoticias);
    }, []);

    return (
        <div className={"Ultimas-informaciones-container"}>
            <h3 style={{margin: "1.5rem auto"}}>{titulo_seccion}</h3>
            <NoticiasCards seccion="noticias" data={noticias}/>
            <div style={{display: 'flex', justifyContent: 'center', fontSize: '1.5rem', margin:'1rem'}}>
                <LinkBox to={`/noticias`}>
                    Ver todas las noticias
                </LinkBox>
            </div>
        </div>
    )
}

export default UltimasNoticias

