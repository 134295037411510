import React from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";

export const LogoRedAulaFutura = ({className}) => {
    return (
        <div className={"image-wrapper"}>
            <img className={className} src={'/Logo-RedAula.png'} alt="logo"/>
        </div>
    )
}
export const LinkLogoRedAulaFutura = ({classNamePosition, className, reloadDocument}) => {
    return (
        <div className={classNamePosition}>
            <Link className={className} reloadDocument={reloadDocument} to={"/"}>
                <LogoRedAulaFutura className={className}/>
            </Link>
        </div>
    );
}


export const MenuNav = (props) => {
    const pathname = useLocation().pathname;
    const secciones = props.secciones

    return (
        <nav className={"Menu-nav"} style={props.style}>
            <ul className={"Menu-ul"}>
                {secciones.map((seccion, index) => {
                    return (
                        <li key={index} className="Menu-li">
                            <NavLink className={`Menu-navlink ${pathname === `/${seccion.to}` ? 'active' : ''}`}
                                     to={`/${seccion.to}`}
                                     reloadDocument={props.reloadDocument}>
                                {seccion.titulo}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}

