import React from 'react'
import './Tabla.css'

export function TablaCabecera(props) {
    const cacabeceras = props.cabeceras

    return (
        <thead>
        {<tr>
            {cacabeceras.map((cacabecera, index) => {
                return (
                    <th key={index}>

                        {cacabecera}
                    </th>
                )
            })}
        </tr>}
        </thead>
    )
}

export function TablaCuerpo(props) {
    return (
        <tbody>
        {props.children}
        </tbody>
    )
}

export function TablaContenido(props) {
    const fila_contenido = props.contenido

    return (
        fila_contenido.map((fila, index) => {
            return (
                <tr key={index}>
                    {fila.map((filaElement, index) => {
                        return (
                            <td key={index}>
                                {filaElement}
                            </td>
                        )
                    })
                    }
                </tr>
            )
        })
    )
}

export function TablaFila(props) {
    return (
        <tr>
            {props.children}
        </tr>
    )
}

export function TablaCelda(props) {
    return (
        <td>
            {props.children}
        </td>
    )
}


export default function Tabla(props) {
    return (
        <div className={"table-wrapper"}>
            <table className={"tabla"}>
                {props.children}
            </table>
        </div>
    )
}
