import React, {useState} from 'react';
import "./Tab.css"
import {InformacionGeneral, Objetivos, MallaCurricular} from "./InformacionCurricular";

function Tab() {

    const [activeTab, setActiveTab] = useState(0);

    const secciones = [
        {
            titulo: "Información General",
            componente: <InformacionGeneral/>
        },
        {
            titulo: "Objetivos",
            componente: <Objetivos/>
        },
        {
            titulo: "Malla Curricular",
            componente: <MallaCurricular/>
        }
    ]

    function switchTabContent(e, index) {
        setActiveTab(index)

    }

    return (
        <div className={"tab"}>
            <div className={"tab-buttons"}>
                {secciones.map((seccion, index) => {
                        return (
                            <button className={activeTab === index ? "tablinksactive" : "tablinks"}
                                    onClick={(e) => {
                                        switchTabContent(e, index)
                                    }}>
                                {seccion.titulo}
                            </button>
                        )
                    }
                )}
            </div>

            <div className={"tab-content"}>
                {secciones.map((seccion, index) => {
                        return (
                            <div style={activeTab !== index ? {display: "none"} : {}}>
                                {seccion.componente}
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

export default Tab
