// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-buttons {
    width: 100%;
    display: flex;
    font-size: 1.3rem;
}

.tab-buttons::before {
    content: "";
    border-bottom: 2px solid var(--azul_unap_claro);
    width: inherit;
}

.tab-buttons::after {
    content: "";
    border-bottom: 2px solid var(--azul_unap_claro);
    width: inherit;

}

.tablinks{
    background-color: var(--azul_unap_claro);
    color: white;
    border: none;
    white-space: nowrap;
    padding: 5px 2rem 6px 2rem;
}

.tablinksactive {
    background-color: inherit;
    color: var(--azul_unap_claro);
    font-weight: 900;
    width: auto;
    border: 2px solid var(--naranjo_red_aula_futura);
    border-bottom: 0;
    white-space: nowrap;
    padding: 5px 2rem 6px 2rem;

}

.tablinks:hover {
    background-color: inherit;
    color: var(--azul_unap_claro);
    font-weight: 900;
    border: 2px solid var(--naranjo_red_aula_futura);
    border-bottom: 0;

}

.tab{
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/formacion_continua/Tab.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,+CAA+C;IAC/C,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,+CAA+C;IAC/C,cAAc;;AAElB;;AAEA;IACI,wCAAwC;IACxC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,gBAAgB;IAChB,WAAW;IACX,gDAAgD;IAChD,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;;AAE9B;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,gBAAgB;IAChB,gDAAgD;IAChD,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".tab-buttons {\n    width: 100%;\n    display: flex;\n    font-size: 1.3rem;\n}\n\n.tab-buttons::before {\n    content: \"\";\n    border-bottom: 2px solid var(--azul_unap_claro);\n    width: inherit;\n}\n\n.tab-buttons::after {\n    content: \"\";\n    border-bottom: 2px solid var(--azul_unap_claro);\n    width: inherit;\n\n}\n\n.tablinks{\n    background-color: var(--azul_unap_claro);\n    color: white;\n    border: none;\n    white-space: nowrap;\n    padding: 5px 2rem 6px 2rem;\n}\n\n.tablinksactive {\n    background-color: inherit;\n    color: var(--azul_unap_claro);\n    font-weight: 900;\n    width: auto;\n    border: 2px solid var(--naranjo_red_aula_futura);\n    border-bottom: 0;\n    white-space: nowrap;\n    padding: 5px 2rem 6px 2rem;\n\n}\n\n.tablinks:hover {\n    background-color: inherit;\n    color: var(--azul_unap_claro);\n    font-weight: 900;\n    border: 2px solid var(--naranjo_red_aula_futura);\n    border-bottom: 0;\n\n}\n\n.tab{\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
