import React from 'react';
import './NoticiaDetalle.css'
import '../../Styles/pagina.css'
import avatar from './avatar.gif'

// TODO css
function NoticiaDetalle(props) {
    const noticia = props.data

    if (noticia) {
        return (<div style={{display: "flex", justifyContent: "center"}}>
            <div style={{display: "grid", width: "100%"}}>
                <div className={"Informacion-detalle-container"}/>
                <div className={"noticia-detalle-contenido"}>
                    <div className={"Informacion-detalle-titulo"} style={{textAlign: "center"}}>
                        <h1>
                            {noticia.titulo}
                        </h1>
                    </div>
                    <div className={"noticia-detalle-fecha"}>
                        <time>{/*noticia.fecha*/} Martes, 05 de marzo del año 2024</time>
                    </div>
                    <div className={"noticia-detalle-imagen"} style={{marginBottom: "2rem"}}>
                        <img className={"noticia-detalle-imagen"} src={noticia.imagen}
                             alt={`imagen_noticia_${noticia.id}`}/>
                    </div>
                    {noticia.bajada ? <div style={{display: "flex"}}>
                        <div style={{aspectRatio: "9/16", height: "100%", width: "15%"}}
                             className={"noticia-detalle-animacion"}>
                            <img style={{height: "100%", objectFit: "contain"}} src={avatar} alt={`avatar`}/>
                        </div>

                        <div className={"Informacion-detalle-bajada"} style={{}}>
                            <p style={{
                                margin: "0",
                            }}>{noticia.bajada}</p>
                        </div>
                    </div> : null}
                    <div className={"Informacion-detalle-cuerpo"}
                         dangerouslySetInnerHTML={{__html: noticia.cuerpo}}/>
                    {noticia.url_original ? <div className={"noticia-detalle-url_original"}><p>Ver noticia original
                        en: <a href={noticia.url_original}>{noticia.url_original}</a></p></div> : null}
                </div>
            </div>
            <div>
                <div style={{borderLeft: "3px solid black", height: "70%"}}/>
                <div style={{borderLeft: "6px solid #F17E31", marginLeft: "-1px", height: "30%"}}/>
            </div>

        </div>);
    }
}

export default NoticiaDetalle;
