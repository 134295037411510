import axios from "axios";

export const getRequest = async (url, setFunction) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/${url}`).then((response) => {
        setFunction(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const postRequest = async (url, formData) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/${url}`, formData)
}

function createAxiosInstanceForRetryingRequest() {
    const axiosInstance = axios.create(
        {
            baseURL: process.env.REACT_APP_API_URL,
        })
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            console.log("interceptor llamado")
            let ret = null
            if (error.response && error.response.status === 401) {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/token/refresh/`,
                    {refresh: localStorage.getItem('refresh_token')},
                    {headers: 'Content-Type: application/json'}).then(
                    async (response) => {
                        if (response.status === 200) {
                            localStorage.setItem('access_token', response.data.access);
                            error.config.headers.Authorization = `Bearer ${response.data.access}`
                            ret = axiosInstance(error.config);
                        }
                    }

                    ).catch(
                    (error) => {
                        if (error.response && error.response.status === 401) {
                            localStorage.clear()
                            window.location.replace(`/ingresar?next=${window.location.pathname}`)
                            return Promise.reject(error)
                        }
                    }
                );
            }
            return ret
        }
    );
    return axiosInstance

}


export const getRequest2 = async (url, setFunction) => {
    const instance = createAxiosInstanceForRetryingRequest()
    await instance.get(url).then((response) => {
        setFunction(response.data);
    }).catch(error => {
        console.log(error);
    });
}


export const POSTAuthorizationRequest = async (url, formdata) => {
    const instance = createAxiosInstanceForRetryingRequest();
    return await instance.post(url, formdata);
}


export const loginRequest = async (url, usuario, next_location) => {
    localStorage.clear();
    axios.post(url, usuario, {headers: {'Content-Type': 'application/json'}},
    ).then((response) => {
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        window.location.replace(next_location);
    }).catch(error => {
        if (error.response.status === 401) {
            return {
                formulario: "Su usuario o contraseña son incorrectos"
            }
        }
        console.log(error);
    });
}
