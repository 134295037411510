import React, {useEffect, useState} from 'react';
import axios from "axios";

import {useParams} from "react-router-dom";
import NoticiasCards from "../Noticias/Components/NoticiasCards";

const noticias_0 = [
    {
        id: 1,
        titulo: "titulo",
        cuerpo: "hola",

    }
]

function NoticiasLandingPage() {

    const pagina = Number(useParams().page)
    const [noticias, setNoticias] = useState(noticias_0)
    const [numeroDePaginas, setNumeroDePaginas] = useState(0)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/noticias/${pagina ? `?pagina=${pagina}` : ''}`).then((response) => {
            setNoticias(response.data.resultados);
            setNumeroDePaginas(response.data.numero_de_paginas)
        }).catch(error => {
            console.log(error);
        });
    }, [pagina])

    return (
        <div className={'pagina-container'}>
            <h1 className={'pagina-titulo'}>Noticias de la Comunidad</h1>
            <NoticiasCards seccion='noticias' data={noticias}/>
        </div>
    )
}

export default NoticiasLandingPage


