import React from 'react';
import {LogoRedAulaFutura} from "../../../componentes/menus/Common";
import './Login.css'
import LoginForm from "./Forms/LoginForm";
import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

export default function Login() {
    const navigate = useNavigate();

    const isLogged = () => {
        /* si el token de acceso no es valido deberia tener uno con refresh, sino, mandar a login
        * se debe verificar que el token sea válido antes de redireccionar u obtener uno nuevo       */
        return localStorage.getItem('access_token') != null
    }

    useEffect(() => {
        if (isLogged())
            navigate('/admin/noticias')
    }, [])

    if (!isLogged())
        return (
            <div className={"login-page-wrapper"}>
                <div className={"login-page-content"}>
                    <LogoRedAulaFutura className={"login-logo"}/>
                    <h1>Ingresar</h1>
                    <LoginForm/>
                </div>
            </div>
        )
}
