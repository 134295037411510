import React, {useEffect, useState} from 'react';
import axios from "axios";

import {useParams} from "react-router-dom";
import ImagenBanner from "../../componentes/carruseles/ImagenBanner";
import UltimasNoticias from "./UltimasNoticias";

import img1 from './imagenes/redaula1.jpeg';
import img2 from './imagenes/redaula2.jpeg';
import img3 from './imagenes/redaula3.jpeg';
import img4 from './imagenes/redaula4.jpeg';
import img5 from './imagenes/redaula5.jpeg';
import img6 from './imagenes/redaula6.jpeg';


function NoticiasLandingPage() {

    const pagina = Number(useParams().page)
    const [noticias, setNoticias] = useState()
    const [numeroDePaginas, setNumeroDePaginas] = useState(0)

    const img = [
        {
            imagen: img1,
            titulo: "Charla Vocacional para el Propedéutico de la UNAP"
        },
        {
            imagen: img2,
            titulo: 'Charla Vocacional para el Propedéutico de la UNAP'
        },
        {
            imagen: img3,
            titulo: 'Trabajo en terreno del equipo de difusión más el director del proyecto'
        },
        {
            imagen: img4,
            titulo: 'Ceremonia de Inauguración del Proyecto Red Aula Futura Tarapacá'
        },
        {
            imagen: img5,
            titulo: 'Reunión con los expetos finaldeses; Yrjö Engeström, Annalisa Sannino y Maria Spante'
        },
        {
            imagen: img6,
            titulo: 'Reunión con el Director del Proyecto, Encargado de Investigación y autoridades del SLEP'
        }
    ]



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/noticias/${pagina ? `?pagina=${pagina}` : ''}`).then((response) => {
            setNoticias(response.data.resultados);
            setNumeroDePaginas(response.data.numero_de_paginas)
        }).catch(error => {
            console.log(error);
        });
    }, [pagina])

    return (
        <>
            <ImagenBanner imagenes={img}/>
            <div className={'pagina-container'}>
                <UltimasNoticias/>
            </div>
        </>
    )
}

export default NoticiasLandingPage


