// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ultimas-informaciones-container {
    margin: 2rem auto;
}

.Ultimas-informaciones-container h1 {
    margin: 2rem 0;
}


.Ultimas-informaciones-button {
    margin: 1rem 0;
    --button-color: black;
    border: 1px solid var(--button-color) !important;
    font-size: 1.3rem !important;
    --bs-btn-color: var(--button-color) !important;
    --bs-btn-border-color: var(--button-color) !important;
    --bs-btn-hover-bg: var(--button-color)!important;
    --bs-btn-hover-border-color: var(--button-color) !important;
    --bs-btn-active-bg: var(--button-color) !important;
    --bs-btn-active-border-color: var(--button-color) !important;
}

@media screen and (max-width: 768px){
    .Ultimas-informaciones-container {
        margin: 1.5rem auto;
    }
    .Ultimas-informaciones-container h1 {
        margin: 1.5rem 0;
    }}
`, "",{"version":3,"sources":["webpack://./src/paginas/Inicio/UltimasNoticias.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI,cAAc;IACd,qBAAqB;IACrB,gDAAgD;IAChD,4BAA4B;IAC5B,8CAA8C;IAC9C,qDAAqD;IACrD,gDAAgD;IAChD,2DAA2D;IAC3D,kDAAkD;IAClD,4DAA4D;AAChE;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,gBAAgB;IACpB,CAAC","sourcesContent":[".Ultimas-informaciones-container {\n    margin: 2rem auto;\n}\n\n.Ultimas-informaciones-container h1 {\n    margin: 2rem 0;\n}\n\n\n.Ultimas-informaciones-button {\n    margin: 1rem 0;\n    --button-color: black;\n    border: 1px solid var(--button-color) !important;\n    font-size: 1.3rem !important;\n    --bs-btn-color: var(--button-color) !important;\n    --bs-btn-border-color: var(--button-color) !important;\n    --bs-btn-hover-bg: var(--button-color)!important;\n    --bs-btn-hover-border-color: var(--button-color) !important;\n    --bs-btn-active-bg: var(--button-color) !important;\n    --bs-btn-active-border-color: var(--button-color) !important;\n}\n\n@media screen and (max-width: 768px){\n    .Ultimas-informaciones-container {\n        margin: 1.5rem auto;\n    }\n    .Ultimas-informaciones-container h1 {\n        margin: 1.5rem 0;\n    }}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
