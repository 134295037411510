import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import NoticiaDetalle from "../../componentes/noticias/NoticiaDetalle";


function NoticiaDetallePage() {
    const id = useParams().id;
    const [queryParams] = useSearchParams()
    const previsualizar = queryParams.get('previsualizar')
    const [noticia, setNoticia] = useState()

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/noticias/${id}/`,
            {withCredentials: true},
        ).then((response) => {
            setNoticia(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, [id, previsualizar])

    return (

        <>
            <div className={'pagina-container'}>
                <NoticiaDetalle tipo="noticia" data={noticia}/>
            </div>
        </>
    )
}

export default NoticiaDetallePage;
