import React from 'react';

function SeccionContainer(props) {
    const titulo = props.titulo

    return (
        <div id={props.id} style={{width: "80%", margin: "0 auto"}}>
            <h2 style={{textAlign: "center", color: "var(--azul_unap_oscuro)", margin: "1.5rem auto 1.5rem auto"}}>{titulo.toUpperCase()}</h2>
            {props.children}
        </div>
    )
}

export default SeccionContainer;
