// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold{
    font-weight: bold;
}


/* images */
.cover{
    object-fit: cover;
}

.flex{
    display: flex;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;;AAGA,WAAW;AACX;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd","sourcesContent":[".bold{\n    font-weight: bold;\n}\n\n\n/* images */\n.cover{\n    object-fit: cover;\n}\n\n.flex{\n    display: flex;\n}\n\nul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
