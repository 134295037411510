import React from 'react'
import logo_tampere_university from "../../../paginas/FormacionContinua/imagenes/logo_tampere_university.png";
import logo_university_of_helsinki from "../../../paginas/FormacionContinua/imagenes/logo_university_of_helsinki.png";
import logo_west_university from "../../../paginas/FormacionContinua/imagenes/logo_west_university.png";
import './Card.css'

export default function Card(props) {

    const elementos = [
        {
            logo: logo_tampere_university,
            titulo: "Universidad de Tampere (Finlandia)",
            descripcion: "",
        },
        {
            logo: logo_university_of_helsinki,
            titulo: "Universidad de Helsinki (Finlandia)",
            descripcion: "",

        },
        {
            logo: logo_west_university,
            titulo: "University West (Suecia)",
            descripcion: "",
            imagen_style: {width: "70%"}
        }
    ]

    return (
        <div className={"card-container"} style={{display: "flex", textAlign: "center"}}>
            {elementos.map((elemento, index) => {
                return (
                    <div key={index} style={{width: "33.33%",  margin: "0 auto"}}>
                        <div style={ {...{margin: "0 auto"}, ...elemento.imagen_style }}>
                            <img src={elemento.logo} alt={"logo_tampere_university"}/>
                        </div>
                        <h4>{elemento.titulo}</h4>
                        <p>{elemento.descripcion}</p>
                    </div>
                )
            })}
        </div>
    )

}
