const noticias = {
    nombre: 'noticias',
    subsecciones: [
        {
            nombre: 'Todas las noticias',
            path: 'noticias',
        },
        {
            nombre: 'Crear',
            path: 'noticias/crear',
        },
    ]
}


export const secciones = [noticias]
