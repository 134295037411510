export const formatearRut = (rut) => {
    let digitos = rut.replace(/[^0-9Kk]/g, '').toLowerCase();

    if (digitos.length <= 1)
        return digitos

    let cuerpo = digitos.substring(0, digitos.length - 1).replace(/\D/g, '')
    let dv = digitos.charAt(digitos.length - 1)
    let cuerpo_reverso = cuerpo.split("").reverse().join("")
    let cuerpo_reverso_formateado = ''
    for (let i = 0; i < cuerpo_reverso.length; i++) {
        if (i % 3 === 0 && i > 0) {
            cuerpo_reverso_formateado = cuerpo_reverso_formateado + '.' + cuerpo_reverso[i]
        } else {
            cuerpo_reverso_formateado += cuerpo_reverso[i]
        }
    }

    let cuerpo_formateado = cuerpo_reverso_formateado.split("").reverse().join("")

    return cuerpo_formateado + '-' + dv
}
