import React from 'react';
import './Footer.css';
import logo_gore from './gore2.png';
import logo_core from './core2.png';
import logo_unap from './logo_unap2.png';
import logo_facultad from './logo_facultad4.png';


function Footer() {

    return (
        <footer className={"footer"}>
            <div className={"logos-container"}>
                <div className={"logos-financia-container"}>
                    <div className={"logos-texto"}>FINANCIA</div>
                    <div className={"logos-imagen"}>
                        <img className={"logo logo-gore"} src={logo_gore} alt={'logo_gore_tarapaca'}/>
                        <img className={"logo logo-core"} src={logo_core} alt={'logo_core_tarapaca'}/>
                    </div>
                </div>
                <div className={"logos-ejecuta-container"}>
                    <div className={"logos-texto"}>EJECUTA</div>
                    <div className={"logos-imagen"}>
                        <img className={"logo logo-unap"} src={logo_unap} alt={'logo_unap'}/>
                        <img className={"logo logo-facultad"} src={logo_facultad} alt={'logo_facultad'}/>
                    </div>
                </div>
            </div>
            <div className={"financiamiento"}>
                Proyecto financiado por el Fondo de Innovación para la Competitividad, FIC, año 2022 del Gobierno
                Regional de Tarapacá
            </div>
        </footer>
    )
}

export default Footer;
