// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-lateral-wrapper {
    background-color: var(--naranjo_red_aula_futura);
    width: 12.5vw;
    min-height: 100vh;
}

.menu-lateral{
    padding: 20px 20px;
}

.menu-lateral ul, .menu-lateral li {
    width: 100%;
}

.menu-lateral li {
    background-color: var(--azul_unap_claro);
}

.menu-lateral a {
    background-color: var(--gris_red_aula_futura);
}

.menu-lateral button{
    width: 100%;
    border: none;
    background-color: var(--azul_unap_claro);
}

.menu-lateral button:hover{
    width: 100%;
    border: none;
    background-color: white;
}

`, "",{"version":3,"sources":["webpack://./src/componentes/menus/admin/menu_lateral/MenuLateral.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".menu-lateral-wrapper {\n    background-color: var(--naranjo_red_aula_futura);\n    width: 12.5vw;\n    min-height: 100vh;\n}\n\n.menu-lateral{\n    padding: 20px 20px;\n}\n\n.menu-lateral ul, .menu-lateral li {\n    width: 100%;\n}\n\n.menu-lateral li {\n    background-color: var(--azul_unap_claro);\n}\n\n.menu-lateral a {\n    background-color: var(--gris_red_aula_futura);\n}\n\n.menu-lateral button{\n    width: 100%;\n    border: none;\n    background-color: var(--azul_unap_claro);\n}\n\n.menu-lateral button:hover{\n    width: 100%;\n    border: none;\n    background-color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
