// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-media-menu{
    padding-top: .2rem;
    padding-bottom: .2rem;


}

.social-media-menu-container{
    background-color: #f5a633;
}

.social-media-menu li{
    margin: 0 .5rem;
}

.social-media-menu li:first-child{
    margin-left: .6rem;
    font-weight: bold;
    font-size: 1.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/menus/redes_sociales/SocialMediaMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;;;AAGzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".social-media-menu{\n    padding-top: .2rem;\n    padding-bottom: .2rem;\n\n\n}\n\n.social-media-menu-container{\n    background-color: #f5a633;\n}\n\n.social-media-menu li{\n    margin: 0 .5rem;\n}\n\n.social-media-menu li:first-child{\n    margin-left: .6rem;\n    font-weight: bold;\n    font-size: 1.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
