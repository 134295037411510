import React from 'react';
import {LinkBox} from "./Utils";
import './NoticiasCards.css';

function NoticiasCards(props) {
    const noticias = props.data;

    if (noticias) {
        return (
            <div className={"noticias-container"}>
                {noticias.map((noticia, index) => {
                    return (
                        <article className={`noticia ${noticia.bajada ? "noticia_grid_3columnas" : "noticia_grid_2columnas"}`} key={index}>
                            <div>
                                <LinkBox to={noticia.id.toString()}>
                                    <img className={"noticia-imagen"} src={noticia.imagen}
                                         alt={noticia.titulo}/>
                                </LinkBox>
                            </div>
                            <div className={"noticia-titulo"}>
                                <h3>{noticia.titulo}</h3>
                            </div>
                            {noticia.bajada ?
                                <div className={"noticia-descripcion"}>
                                    {noticia.bajada}
                                </div> : null
                            }
                        </article>
                    )
                })}
            </div>
        )
    }
}

export default NoticiasCards;
