import React, {useEffect, useState} from 'react';
import axios from "axios";
import '../../Styles/pagina.css'

import {useParams} from "react-router-dom";
import NoticiasCards from "./Components/NoticiasCards";

function NoticiasLandingPage() {

    const pagina = Number(useParams().page)
    const [noticias, setNoticias] = useState([])
    const [numeroDePaginas, setNumeroDePaginas] = useState(0)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/noticias/${pagina ? `?pagina=${pagina}` : ''}`).then((response) => {
            setNoticias(response.data);
            setNumeroDePaginas(response.data.numero_de_paginas)
        }).catch(error => {
            console.log(error);
        });
    }, [pagina])

    return (
        <div className={'pagina-container'}>
            <h1 className={'pagina-titulo'}>Conoce las últimas noticias del mundo de la educación</h1>
            <NoticiasCards seccion='noticias' data={noticias}/>
        </div>
    )
}

export default NoticiasLandingPage


